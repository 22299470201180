import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { FormDataList, MarkedCardiacList, Newdata } from './annual-wellness.model';

@Injectable({
  providedIn: 'root'
})
export class AnnualWellnessService {
  formApiUrl: string;

  constructor(private http: HttpClient) {
    this.formApiUrl = environment.formApiUrl;
  }

  getNewInvitations(status: string, formName:string): Observable<any> {
    var url = `${this.formApiUrl}/forms/internal/adhoc/${formName}/invitations?status=${status}`
    return this.http.get<any>(url);
  }

  moveToArchive(id: string, formName:string): Observable<any> {
    var url = `${this.formApiUrl}/forms/internal/adhoc/${formName}/archive/${id}`
    return this.http.delete<any>(url);
  }

  reinvitePatient(id: string, formName:string): Observable<any> {
    var url = `${this.formApiUrl}/forms/internal/adhoc/${formName}/reinvite/${id}`
    return this.http.post<any>(url, {});
  }

  getAllInvitations(status: string): Observable<any> {
    var url = `${this.formApiUrl}/forms/internal/adhoc/invitations?status=${status}`
    return this.http.get<any>(url);
  }

  markedCardiacScreening(): Observable<MarkedCardiacList> {
    var url = `${this.formApiUrl}/forms/internal/adhoc/stats`
    return this.http.get<MarkedCardiacList>(url);
  }

  markedCardiacScreeningList(flagCardiac: boolean,status:string): Observable<any> {
    var url = `${this.formApiUrl}/forms/internal/adhoc/cardiac-screening/invitations?status=${status}&flagCardiac=${flagCardiac}`
    return this.http.get<any>(url);
  }

}
