<form >
<div class="">
    <div class="">
        <div class="text-20-bold-700 line-height-48">{{formHeading.formName}}</div>
        <div class="line-height-48">
            <span class="text-20-bold-700">Medical practice: </span>
            <span class="text-20-bold-400">{{formHeading.clinicName}}</span>
        </div>
        <div class="line-height-48">
            <span class="text-20-bold-700">Document created: </span>
            <span class="text-20-bold-400" *ngIf="formHeading.createdAt == null">{{todayDate | date: 'MMMM dd yyyy'}}</span>
      <span class="text-20-bold-400" *ngIf="formHeading.createdAt !== null">{{formHeading.createdAt | date: 'MMMM dd yyyy'}}</span>

        </div>
    </div>
    <span class="text-20-bold-400" *ngIf="!Isreview">Please enter your date of birth to identify yourself</span>

    <div class="wrap-box" *ngIf="!Isreview">
        <div>
            <label class="label-font-14">Date of birth</label>
        </div>
        <mat-form-field floatLabel="never" class="mat-datepicker" (click)="picker.open()">
          <input matInput [matDatepicker]="picker" autocomplete="off" [value]="dateofbirths.value" name="dateofbirth" (dateChange)="onDateInput($event)">
          <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
        <!-- <input class=" custom-input width-100" type="date"  [(ngModel)]="dateofbirth" name="dateofbirth"> -->

          <label class="text-20-bold-400 text-danger text-center margint-topbottum" *ngIf="ISInvalid" >Incorrect date. Please enter the correct date of birth of the patient.</label>
    </div>
    <div class="wrap-box" *ngIf="Isreview">
          <label class="text-20-bold-400 text-danger text-center margint-topbottum" *ngIf="Isreview">Unfortunately your form is currently under the review, please contact clinic if you need to change provided information</label>
    </div>
    <div class="wrap-box">
        <button class="btn-save" (click)="next()" type="submit">Save and continue</button>
    </div>
</div>
</form>
