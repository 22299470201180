<form>
  <div class="">
  <div class="">
    <div><span class="text-20-bold-700 line-height-48">{{formHeading.formName}}</span></div>
    <div class="line-height-48">
      <span class="text-20-bold-700">Medical practice: </span>
      <span class="text-20-bold-400">{{formHeading.clinicName}}</span>
    </div>
    <div class="line-height-48">
      <span class="text-20-bold-700">Document created: </span>
      <span class="text-20-bold-400" *ngIf="formHeading.createdAt == null">{{todayDate | date: 'MMMM dd yyyy'}}</span>
      <span class="text-20-bold-400" *ngIf="formHeading.createdAt !== null">{{formHeading.createdAt | date: 'MMMM dd yyyy'}}</span>
    </div>
    <div *ngIf="isClinicView">
        <span class="text-20-bold-700">Note: </span>
        <span class="text-20-bold-400">Please fill out the form to your best abilities. Skip the questions you don’t know the
        answers to. Your care team will be able to complete the form after you submit it.</span>
    </div>
  </div>
  <div class="text-20-bold-700 line-height-48">Patient details:</div>

  <div class="wrap-box">
    <div>
      <label class="label-font-14">First name</label>
    </div>
    <input class="custom-input " type="text" placeholder="Enter first name" [(ngModel)]="patient.firstName" name="firstName" disabled="true"/>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Last name</label>
    </div>
    <input class="custom-input " type="text" placeholder="Enter last name" [(ngModel)]="patient.lastName" name="lastName" disabled="true"/>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Date of birth</label>
    </div>
    <input class="custom-input " type="date" placeholder="Enter first name"  [(ngModel)]="patient.dateOfBirth" name="dateOfBirth" disabled="true"/>
  </div>
  <div class="wrap-box" *ngIf="!isClinicView">
    <h2 _ngcontent-bco-c54="" class="blue-header">Type of visit</h2>
  </div>
</div>

  <div class="mt-3" *ngIf="!isClinicView">
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="typeOfVisit" id="typeOfVisit1" value="TELEHEALTH_VISIT" [(ngModel)]="step1.typeOfVisit" (ngModelChange)="onTypeOfVisitChange(step1.typeOfVisit)">
        <label for="typeOfVisit1">1. Telehealth visit</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="typeOfVisit" id="typeOfVisit2" value="INPERSON_DOCTOR_VISIT" [(ngModel)]="step1.typeOfVisit" (ngModelChange)="onTypeOfVisitChange(step1.typeOfVisit)">
        <label for="typeOfVisit2">2. In-person doctor's visit</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="typeOfVisit" id="typeOfVisit3" value="NURSING_HOME_VISIT" [(ngModel)]="step1.typeOfVisit" (ngModelChange)="onTypeOfVisitChange(step1.typeOfVisit)">
        <label for="typeOfVisit3">3. Nursing home visit</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="typeOfVisit" id="typeOfVisit4" value="LIVING_FACILITY_VISIT" [(ngModel)]="step1.typeOfVisit" (ngModelChange)="onTypeOfVisitChange(step1.typeOfVisit)">
        <label for="typeOfVisit4">4. Assisted living facility visit</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" name="typeOfVisit" id="typeOfVisit5" value="OTHER" [(ngModel)]="step1.typeOfVisit" (ngModelChange)="onTypeOfVisitChange(step1.typeOfVisit)">
        <label for="typeOfVisit5">5. Other</label>
      </div>
    </div>
    <div class="wrap-box">
      <input class="custom-input" type="text" [disabled]="step1.typeOfVisit !== 'OTHER'" name="visitComment" [(ngModel)]="step1.visitComment">
    </div>
  </div>

  <div class="wrap-box">
    <h2 _ngcontent-bco-c54="" class="blue-header">Demographics (required)</h2>
  </div>

  <div class="wrap-box">
    <div>
      <label class="label-font-14">Gender</label>
    </div>
    <select class="custom-input " name="gender" [(ngModel)]="step1.gender">
      <option value="" disabled> Select One</option>
      <option value="MALE"> Male</option>
      <option value="FEMALE"> Female</option>
    </select>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Current marital status</label>
    </div>
    <select class="custom-input "  name="maritialStatus" [(ngModel)]="step1.maritialStatus">
      <option value="" disabled> Select One</option>
      <option value="SINGLE">Single</option>
      <option value="MARRIED">Married</option>
      <option value="DIVORCED">Divorced</option>
      <option value="SEPARATED">Separated</option>
      <option value="WIDOWED">Widowed</option>
      <option value="RELATIONSHIP">In relationship</option>
    </select>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Person answering questionnaire</label>
    </div>
    <select class="custom-input " name="personAnswering" [(ngModel)]="step1.personAnswering">
      <option value="" disabled> Select One</option>
      <option value="PATIENT">Patient</option>
      <option value="CAREGIVER">Caregiver</option>
      <option value="OTHER">Other</option>
    </select>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Comments</label>
    </div>
    <input class="custom-input " type="text" placeholder="Input comment" name="comments" [(ngModel)]="step1.comments">
  </div>
  <div class="wrap-box">
    <h2 _ngcontent-bco-c54="" class="blue-header">General health assessment</h2>
  </div>
  <div *ngIf="!isClinicView">
  <div class="wrap-box">
    <label class="label-font-14">Age of patient (calculation)</label>
  </div>
  <div class="wrap-box wrap-radio">
    <label>{{calculateAge(patient.dateOfBirth)}}</label>
  </div>
  <div class="wrap-box">
    <label class="label-font-14">1. Body mass screening</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="bodyMassScreening" name="bodyMass" [(ngModel)]="step1.bodyMass" value="BODYMASS_AGE_EXCLUSION">
      <label for="bodyMassScreening" >Patient has age exclusion (+74)</label>
    </div>
  </div>
  <div class="wrap-box">
    <label class="label-font-14">2. Colorectal screening</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="colorectalScreening1" name="colorectal" [(ngModel)]="step1.colorectal" value="COLORECTAL_AGE_EXCLUSION">
      <label for="colorectalScreening1" >Patient has age exclusion (+74)</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="colorectalScreening2" name="colorectal" [(ngModel)]="step1.colorectal" value="FLEXIBLE_SIGMOIDOSCOPY">
      <label for="colorectalScreening2">Patient had flexible sigmoidoscopy in the last
        4 years</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio"  id="colorectalScreening3" name="colorectal" [(ngModel)]="step1.colorectal" value="COLONOSCOPY">
      <label for="colorectalScreening3">Patient had colonoscopy in the last 10 years</label>
    </div>
  </div>
  <div class="wrap-box">
    <label class="label-font-14">Date of last colorectal screening</label>
  </div>
  <div class="wrap-box">
    <!-- <input class="custom-input " type="date" name="dateFoLastColorectal" [(ngModel)]="step1.dateFoLastColorectal"> -->
    <mat-form-field floatLabel="never" class="mat-datepicker" (click)="picker.open()">
      <input matInput [matDatepicker]="picker" autocomplete="off" [value]="dateFoLastColorectals.value" name="dateofbirth" placeholder="MM/DD/YYYY" (dateChange)="onDateInput($event)">
      <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  </div>
  <div class="wrap-box">
    <label class="label-font-14">3. Breast cancer screening (women only)</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="breastCancerScreening1" name="breastCancer" [(ngModel)]="step1.breastCancer" value="BREAST_CANCER_AGE_EXCLUSION">
      <label for="breastCancerScreening1">Patient has age exclusion (+74)</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="breastCancerScreening2" name="breastCancer" [(ngModel)]="step1.breastCancer" value="MAMMOGRAM">
      <label for="breastCancerScreening2">Mammogram in the last 24 months</label>
    </div>
  </div>

  <div class="wrap-box">
    <label class="label-font-14">Date of last breast cancer screening</label>
  </div>
  <div class="wrap-box">
    <!-- <input class="custom-input " type="date" name="dateOfLastBreastCancer" [(ngModel)]="step1.dateOfLastBreastCancer"> -->
    <mat-form-field floatLabel="never" class="mat-datepicker" (click)="pickers.open()">
      <input matInput [matDatepicker]="pickers" autocomplete="off" [value]="dateOfLastBreastCancers.value" name="dateofbirth" placeholder="MM/DD/YYYY" (dateChange)="onDatechange($event)">
      <mat-datepicker-toggle matSuffix [for]="pickers" ></mat-datepicker-toggle>
      <mat-datepicker #pickers></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="wrap-box">
    <label class="label-font-14">4. Blood pressure screening</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="bloodPressureScreening" name="bloodPressure" [(ngModel)]="step1.bloodPressure" value="BLOOD_PRESSURE_AGE_EXCLUSION">
      <label for="bloodPressureScreening" >Patient has age exclusion (+85)</label>
    </div>
  </div>
  <div class="wrap-box">
    <label class="label-font-14">5. Diabetic eye exam</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="diabeticEyeExam" name="diabeticEyeExam" [(ngModel)]="step1.diabeticEyeExam" value="DIABETIC_EYE_EXAM_AGE_EXCLUSION">
      <label for="diabeticEyeExam" >Patient has age exclusion (+75)</label>
    </div>
  </div>
  <div class="wrap-box">
    <label class="label-font-14">6. Nephropathy screening</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="NephropathyScreening" name="nephropathy" [(ngModel)]="step1.nephropathy" value="NEPHROPATHY_AGE_EXCLUSION">
      <label for="NephropathyScreening" >Patient has age exclusion (+75)</label>
    </div>
  </div>
  <div class="wrap-box">
    <label class="label-font-14">7. Bone density test</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="boneDensityTest" name="boneDensityTest" [(ngModel)]="step1.boneDensityTest" value="BONE_DENSITY_TEST_AGE_EXCLUSION">
      <label for="boneDensityTest">Patient has age exclusion (+85)</label>
    </div>
  </div>
</div>
  <div class="que8">
    <div class="wrap-box">
      <label class="label-font-14">8. Questions to the patient</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 1.</label>
        <label> Considering your age, how would you rate your overall health.</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="overallHealth1" name="overallHealth" [(ngModel)]="step1.overallHealth" value="EXCELLENT">
        <label for="overallHealth1">Excellent</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="overallHealth2" name="overallHealth" [(ngModel)]="step1.overallHealth" value="GOOD">
        <label for="overallHealth2" >Good</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio"id="overallHealth3" name="overallHealth" [(ngModel)]="step1.overallHealth" value="POOR">
        <label for="overallHealth3">Poor</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 2. </label>
        <label> In general, what is your satisfaction with life?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="lifeSatisfaction1" name="lifeSatisfaction" [(ngModel)]="step1.lifeSatisfaction" value="EXCELLENT">
        <label for="lifeSatisfaction1">Excellent</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="lifeSatisfaction2" name="lifeSatisfaction" [(ngModel)]="step1.lifeSatisfaction" value="GOOD">
        <label for="lifeSatisfaction2">Good</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="lifeSatisfaction3" name="lifeSatisfaction" [(ngModel)]="step1.lifeSatisfaction" value="POOR">
        <label for="lifeSatisfaction3">Poor</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 3. </label>
        <label> How would you describe the condition of your mouth
          and teeth, including false teeth or dentures?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="mouthTeethcondition1" name="mouthteethCondition" [(ngModel)]="step1.mouthteethCondition" value="EXCELLENT">
        <label for="mouthTeethcondition1">Excellent</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="mouthTeethcondition2" name="mouthteethCondition" [(ngModel)]="step1.mouthteethCondition" value="GOOD">
        <label for="mouthTeethcondition2">Good</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="mouthTeethcondition3" name="mouthteethCondition" [(ngModel)]="step1.mouthteethCondition" value="POOR">
        <label for="mouthTeethcondition3">Poor</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 4. </label>
        <label> How would you rate your mental health, including your
          mood and ability to think?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="mentalHealth1" name="mentalHealth" [(ngModel)]="step1.mentalHealth" value="EXCELLENT">
        <label for="mentalHealth1">Excellent</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="mentalHealth2" name="mentalHealth" [(ngModel)]="step1.mentalHealth" value="GOOD">
        <label for="mentalHealth2" >Good</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="mentalHealth3" name="mentalHealth" [(ngModel)]="step1.mentalHealth" value="POOR">
        <label for="mentalHealth3" >Poor</label>
      </div>
    </div>
    <div class="wrap-box">
      <label class="label-font-14">Comments</label>
    </div>
    <div class="wrap-box">
      <input _ngcontent-her-c53="" type="text" class="custom-input" name="mentalHealthComment" [(ngModel)]="step1.mentalHealthComment">
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 5. </label>
        <label> How often do you snore?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="snoreRating1" name="snore" [(ngModel)]="step1.snore" value="FREQUENTLY">
        <label for="snoreRating1">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="snoreRating2" name="snore" [(ngModel)]="step1.snore" value="OFTEN">
        <label for="snoreRating2">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="snoreRating3" name="snore" [(ngModel)]="step1.snore" value="RARELY">
        <label for="snoreRating3">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 6.</label>
        <label> In the past 7 days, how often have you felt sleepy during the daytime?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="feltSleepyRating1" name="feltSleepy" [(ngModel)]="step1.feltSleepy" value="FREQUENTLY">
        <label for="feltSleepyRating1">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="feltSleepyRating2" name="feltSleepy" [(ngModel)]="step1.feltSleepy" value="OFTEN">
        <label for="feltSleepyRating2">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="feltSleepyRating3" name="feltSleepy" [(ngModel)]="step1.feltSleepy" value="RARELY">
        <label for="feltSleepyRating3">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 7.</label>
        <label> In the past 7 days, have you experienced any pain?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="painRating1" name="experiencedPain" [(ngModel)]="step1.experiencedPain" value="FREQUENTLY">
        <label for="painRating1">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="painRating2" name="experiencedPain" [(ngModel)]="step1.experiencedPain" value="OFTEN">
        <label for="painRating2">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="painRating3" name="experiencedPain" [(ngModel)]="step1.experiencedPain" value="RARELY">
        <label for="painRating3">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 8.</label>
        <label> Did pain interfere with your day to day activities?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="painDuringActivity1" name="painDuringActivity" [(ngModel)]="step1.painDuringActivity" value="FREQUENTLY">
        <label for="painDuringActivity1">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="painDuringActivity2" name="painDuringActivity" [(ngModel)]="step1.painDuringActivity" value="OFTEN">
        <label for="painDuringActivity2">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="painDuringActivity3" name="painDuringActivity" [(ngModel)]="step1.painDuringActivity" value="RARELY">
        <label for="painDuringActivity3">Rarely</label>
      </div>
    </div>
  </div>
  <div class="wrap-box">
    <label class="label-font-14">Rate level of pain on scale 1-10 (1 being the lowest)
    </label>
  </div>
  <div class="wrap-box">
    <input type="number" class="custom-input" name="painLevel" [(ngModel)]="step1.painLevel" min="1" max="10">
  </div>
  <div class="wrap-box">
    <label class="label-font-14">Have you suffered a personal loss or misfortune in the
      last year? (a job loss, death of someone, divorce etc)</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="personalLosses1" name="personalLoss" [(ngModel)]="step1.personalLoss" value="ONE_SERIOUS_LOSS">
      <label for="personalLosses1">Yes, one serious loss</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="personalLosses2" name="personalLoss" [(ngModel)]="step1.personalLoss" value="TWO_SERIOUS_LOSS">
      <label for="personalLosses2">Yes, two or more serious losses</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="personalLosses3" name="personalLoss" [(ngModel)]="step1.personalLoss" value="NO">
      <label for="personalLosses3">No</label>
    </div>
  </div>

  <div class="wrap-box">
    <label class="label-font-14">Comments

    </label>
  </div>
  <div class="wrap-box">
    <input type="text" class="custom-input" name="pesonalLossComment" [(ngModel)]="step1.pesonalLossComment" required >
  </div>
  <div class="wrap-box">
    <label class="label-font-14">How many hours do you usually sleep each night?
    </label>
  </div>
  <div class="wrap-box">
    <input type="text" class="custom-input" placeholder="Sleep Hours" name="sleepHours" [(ngModel)]="step1.sleepHours" required minlength="3" maxlength="20">
  </div>

  <div class="wrap-box" *ngIf="isClinicView">
    <button class="btn-save" (click)="save()">Save and continue</button>
  </div>
</form>
