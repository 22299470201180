import { Component, Input } from '@angular/core';
import { AnnualWellnessVisitFormComponent } from '../annual-wellness-visit-form.component';
import { FormHeading, fullForm, Patient, Step1 } from '../annualwellness.model';
import { AnnualWellnessService } from '../annualwellness.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss'],
})
export class PatientDetailsComponent {
  @Input() isClinicView: boolean = true;
  form!: UntypedFormGroup;
  formHeading:FormHeading = new FormHeading();

  constructor(
    private layout: AnnualWellnessVisitFormComponent,
    private AnnualWellnessService: AnnualWellnessService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  todayDate = moment(new Date()).format('MMMM DD yyyy');
  fullForm: fullForm;
  id: string = '';

  patient: Patient={
    firstName: '',
    lastName: '',
    dateOfBirth: ''
  }
  dateFoLastColorectals: any = new UntypedFormControl();
  dateOfLastBreastCancers: any = new UntypedFormControl();
  step1: Step1 = {
    typeOfVisit: '',
    visitComment: '',
    gender: '',
    maritialStatus: '',
    personAnswering: '',
    comments: '',
    bodyMass: '',
    colorectal: '',
    dateFoLastColorectal: '',
    breastCancer: '',
    dateOfLastBreastCancer: '',
    bloodPressure: '',
    diabeticEyeExam: '',
    nephropathy: '',
    boneDensityTest: '',
    overallHealth: '',
    lifeSatisfaction: '',
    mouthteethCondition: '',
    mentalHealth: '',
    mentalHealthComment: '',
    snore: '',
    feltSleepy: '',
    experiencedPain: '',
    painDuringActivity: '',
    painLevel: '',
    personalLoss: '',
    pesonalLossComment: '',
    sleepHours: ''
  };

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    if(this.isClinicView){
      this.GetPatientdetails();
    }else{
      this.GetDataForClinic();
    }
    this.GetAWVHeading();
  }
  onDateInput(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    this.step1.dateFoLastColorectal = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
  }
  onDatechange(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    this.step1.dateOfLastBreastCancer= this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
  }
  onTypeOfVisitChange(value: string) {
    if (value !== 'OTHER') {
      this.step1.visitComment = '';
    }
  }

  // get data for patients
  GetPatientdetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.AnnualWellnessService.getAWVDataForPatient(this.id, dateOfBirth).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.patient = results.patient;
        this.step1 = {
        typeOfVisit: results.step1.typeOfVisit || '',
        visitComment: results.step1.visitComment || '',
        gender: results.step1.gender || '',
        maritialStatus: results.step1.maritialStatus || '',
        personAnswering: results.step1.personAnswering || '',
        comments: results.step1.comments || '',
        bodyMass: results.step1.bodyMass || '',
        colorectal: results.step1.colorectal || '',
        dateFoLastColorectal: results.step1.dateFoLastColorectal || '',
        breastCancer: results.step1.breastCancer || '',
        dateOfLastBreastCancer: results.step1.dateOfLastBreastCancer || '',
        bloodPressure: results.step1.bloodPressure || '',
        diabeticEyeExam: results.step1.diabeticEyeExam || '',
        nephropathy: results.step1.nephropathy || '',
        boneDensityTest: results.step1.boneDensityTest || '',
        overallHealth: results.step1.overallHealth || '',
        lifeSatisfaction: results.step1.lifeSatisfaction || '',
        mouthteethCondition: results.step1.mouthteethCondition || '',
        mentalHealth: results.step1.mentalHealth || '',
        mentalHealthComment: results.step1.mentalHealthComment || '',
        snore: results.step1.snore || '',
        feltSleepy: results.step1.feltSleepy || '',
        experiencedPain: results.step1.experiencedPain || '',
        painDuringActivity: results.step1.painDuringActivity || '',
        painLevel: results.step1.painLevel || '',
        personalLoss: results.step1.personalLoss || '',
        pesonalLossComment:results.step1.pesonalLossComment || '',
        sleepHours:results.step1.sleepHours || '',
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id]);
      }
    );
  }

  // get data for clinic
  GetDataForClinic() {
    this.AnnualWellnessService.getAWVDataForClinic(this.id).subscribe(
      (results: any) => {
        this.fullForm = results;
        if(results.patient){
          this.patient = results.patient;
          }
        this.step1 = {
        typeOfVisit: results.step1.typeOfVisit || '',
        visitComment: results.step1.visitComment || '',
        gender: results.step1.gender || '',
        maritialStatus: results.step1.maritialStatus || '',
        personAnswering: results.step1.personAnswering || '',
        comments: results.step1.comments || '',
        bodyMass: results.step1.bodyMass || '',
        colorectal: results.step1.colorectal || '',
        dateFoLastColorectal: results.step1.dateFoLastColorectal || '',
        breastCancer: results.step1.breastCancer || '',
        dateOfLastBreastCancer: results.step1.dateOfLastBreastCancer || '',
        bloodPressure: results.step1.bloodPressure || '',
        diabeticEyeExam: results.step1.diabeticEyeExam || '',
        nephropathy: results.step1.nephropathy || '',
        boneDensityTest: results.step1.boneDensityTest || '',
        overallHealth: results.step1.overallHealth || '',
        lifeSatisfaction: results.step1.lifeSatisfaction || '',
        mouthteethCondition: results.step1.mouthteethCondition || '',
        mentalHealth: results.step1.mentalHealth || '',
        mentalHealthComment: results.step1.mentalHealthComment || '',
        snore: results.step1.snore || '',
        feltSleepy: results.step1.feltSleepy || '',
        experiencedPain: results.step1.experiencedPain || '',
        painDuringActivity: results.step1.painDuringActivity || '',
        painLevel: results.step1.painLevel || '',
        personalLoss: results.step1.personalLoss || '',
        pesonalLossComment:results.step1.pesonalLossComment || '',
        sleepHours:results.step1.sleepHours || '',
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/forms']);
      }
    );
  }

  public calculateAge(birthdate: any): number {
    return moment().diff(birthdate, 'years');
  }

  save() {
    this.fullForm.patient = this.patient;
    this.fullForm.step1 = this.step1;
    this.AnnualWellnessService.submitAWVDataForPatient(
      this.id,
      this.fullForm
    ).subscribe(
      (results) => {
        this.layout.onNextComponentClick();
      },
      (error) => { }
    );
  }
  GetAWVHeading() {
    this.AnnualWellnessService.GetAWVHeadingData(this.id).subscribe(
      (results: FormHeading) => {
        this.formHeading = results;
      },
      (error) => {
       }
    );
  }
  getData() {
    return this.step1;
  }
}
