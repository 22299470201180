import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Billing } from '../shared/models/patient.model';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '@app/users/user.service';
import { AnnualFormStatus, fullForm ,ResponseInvits,InvitePatient, FormHeading } from './annualwellness.model';
import moment from 'moment';;
import { FormGroup } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class AnnualWellnessService {
  formApiUrl: string;

  constructor(private http: HttpClient, private userService: UserService) {
    this.formApiUrl = environment.formApiUrl;
  }

  // get AWV form data for patient
  getAWVDataForPatient(id: string, dateofbirth: string): Observable<AnnualFormStatus> {
    var url = `${this.formApiUrl}/forms/response/annual-wellness-visit/get/${id}`
    let patient = {
      "patient": {
        "dateOfBirth": dateofbirth
        }
      }
    return this.http.post<AnnualFormStatus>(url, patient);
  }

  // Save AWV form data for patient
  submitAWVDataForPatient(id: string, formData: fullForm): Observable<AnnualFormStatus> {
    var url = `${this.formApiUrl}/forms/response/annual-wellness-visit/post/${id}`
    return this.http.post<AnnualFormStatus>(url, formData);
  }

  // get AWV form data for clinic
  getAWVDataForClinic(id: string): Observable<AnnualFormStatus> {
  var url = `${this.formApiUrl}/forms/internal/adhoc/annual-wellness-visit/get/${id}`
  return this.http.get<AnnualFormStatus>(url);
  }

  // Save AWV form data for clinic
  submitAWVDataForClinic(id: string, formData: fullForm): Observable<AnnualFormStatus> {
    var url = `${this.formApiUrl}/forms/internal/adhoc/annual-wellness-visit/post/${id}`
    return this.http.post<AnnualFormStatus>(url, formData);
  }

  InviteAWVPatient( invitepatient: InvitePatient[]): Observable<ResponseInvits> {
    var url = `${this.formApiUrl}/forms/internal/adhoc/invite`
    return this.http.post<ResponseInvits>(url, invitepatient);
  }

  GetAWVHeadingData(id: string): Observable<FormHeading> {
    var url = `${this.formApiUrl}/forms/head/annual-wellness-visit/${id}`
    return this.http.get<FormHeading>(url);
  }
}
